<template>
    <div id="modelUpload">

    </div>
</template>

<script>

    export default {
        data() {
            return {

            };
        },
        mounted() {


        },
        methods: {

        },
    };
</script>

<style scoped lang="scss">
    #modelUpload{

    }
</style>
