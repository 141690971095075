<template>
    <div id="imgUpload">
        <!--返回之前页面-->
        <div
                class="getBack"
                @click="$router.back()"
        >
            <i class="ivu-icon ivu-icon-ios-arrow-back"></i>
            <span>返回</span>
        </div>
        <div class="wrap">
            <div class="TaskName">
                <h1>云计算任务名称</h1>
                <div class="searchProject">
                    <input type="text" v-model="taskName" placeholder="填写您的云计算名称" >
                </div>
            </div>
            <div class="UploadContent" @click.stop="clickUploadButton('first')" v-show="UploadShou">
                <i class="ivu-icon ivu-icon-md-images"></i>
                <Button type="primary" class="Button">上传照片</Button>
            </div>
            <input
                    type="file"
                    name=""
                    id="file"
                    class="ImgFile"
                    ref="ImgFile"
                    multiple="multiple"
                    @change="fileImport($event)"
                    compress='false'
                    style="display: none"
            />
            <div class="imgListContent" v-if="!UploadShou">
                <Button type="primary" class="Button" @click="clickUploadButton('add')" style="margin-right: 10px">增加图片</Button>
                <Button type="error" class="Button" @click="deleteImg=true" v-if="!deleteImg">删除</Button>
                <Button type="error" class="Button" @click="deleteImg=false" v-if="deleteImg">解除删除状态</Button>
                <Button type="success" @click="CreateAndUpload"  style="float: right">上传</Button>
                <div class="imgList">
                    <div class="imgItem" v-for="(item,ind) in getImgList" :key="ind">
                        <img :src="item.url" alt="">
                        <div class="deleteImg" v-if="deleteImg" @click="deleteImgFn(item,ind)">
                            <i class="ivu-icon ivu-icon-ios-close-circle"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <input
                type="file"
                ref="ImgMsgFile"
                accept=".json"
                @change="uploadingOSS($event)"
                style="display: none"
        />
        <!--<Spin fix v-if="UploadProgress==1">生成照片信息文件中...</Spin>-->
        <!--<Spin fix v-if="UploadProgress==2">-->
            <!--<Button type="primary" label="large"  class="Button" @click="getImgMag()" >选择照片信息文件</Button>-->
        <!--</Spin>-->
        <!--<Spin fix v-if="UploadProgress==3">-->
            <!--<h1 style="font-size: 20px ; margin-bottom: 30px">上传照片文件中...</h1>-->
            <!--<Progress-->
                    <!--style="width: 300px"-->
                    <!--status="active"-->
                    <!--:percent="uploadingPercentVal"-->
                    <!--:stroke-color="['#108ee9', '#87d068']"-->
            <!--/>-->
        <!--</Spin>-->
        <div class="Spin" v-if="UploadProgress>0">
            <div class="procedureBox">
                <div class="text">
                    <div v-if="UploadProgress<=1">
                        <h1>1,生成照片信息文件中...</h1>
                        <img :src="Icon.spin" alt="">
                    </div>
                    <div v-else>
                        <h1>1,生成照片信息文件</h1>
                        <i class="more_i ivu-icon ivu-icon-md-checkmark" ></i>
                    </div>
                </div>
                <div class="text">
                    <div v-if="UploadProgress<=1">
                        <h1>2,选择照片信息文件</h1>
                    </div>
                    <div v-if="UploadProgress==2">
                        <div style="display: flex;">
                            <h1>2,选择照片信息文件</h1>
                            <Button
                                    type="primary"
                                    label="large"
                                    @click="getImgMag()"
                                    icon="ios-browsers"
                            ></Button>
                        </div>

                        <img :src="Icon.spin" alt="">

                    </div>
                    <div v-if="UploadProgress==3">
                        <h1>2,选择照片信息文件</h1>
                        <i class="more_i ivu-icon ivu-icon-md-checkmark" ></i>
                    </div>

                </div>
                <div class="text">
                    <div v-if="UploadProgress<3">
                        <h1>3,上传照片文件</h1>
                    </div>
                    <div v-if="UploadProgress==3">
                        <h1>3,上传照片文件中...</h1>
                        <img :src="Icon.spin" alt="">
                        <Progress
                                style="width: 300px"
                                status="active"
                                :percent="uploadingPercentVal"
                                :stroke-color="['#108ee9', '#87d068']"
                        />
                    </div>
                    <div v-if="UploadProgress==4">
                        <h1>3,上传照片文件</h1>
                        <i class="more_i ivu-icon ivu-icon-md-checkmark" ></i>
                    </div>

                </div>
            </div>
            <div class="bgc"></div>
        </div>
    </div>
</template>

<script>
    import OSS from 'ali-oss';
    import FileSaver  from 'file-saver';
    import sha1 from 'js-sha1';
    import TerraTaskList from "./TerraTaskList";

    import spin from '@/assets/img/Icon/Spin.gif'

    export default {
        props: {
            client: {
                type: Object,
                required: false,
                default: () => {}
            },
            CreateTaskResult: {
                type: Object,
                required: false,
                default: () => {}
            },
        },
        data() {
            return {
                Icon:{
                    spin
                },
                OSSdata:{},
                taskName:'',
                UploadShou:true,
                deleteImg:false,
                UpLoadStatus:'',//拉取文件的状态
                getImgList:[],//拉取的图片集合
                uploadingFileList:[],//上传的图片集合
                uploadingPercentVal:null,//上传照片成功的数量
                upload_callback_data:{},
                returnMsgList:[],
                str:[],
                UploadProgress:0,//0,未开始 1.创建资源作业扫描图片获取图片信息 2,状态1成功 3,上传图片->完成后进行Terra上传文件回调
                TerrataskData:null,//创建的云计算任务数据
            };
        },
        computed: {
            getTaskData() {
                return (
                    JSON.parse(window.sessionStorage.getItem("getTaskData") || "{}")
                );
            },
            userInfo() {
                return (
                    JSON.parse(window.sessionStorage.getItem("user_info") || "{}")
                );
            },
            Terra_token(){
                return (
                    JSON.parse(window.sessionStorage.getItem("Terra_token") || "{}")
                );
            },
        },
        mounted() {

            var a=(new Date()).toLocaleDateString();
            this.taskName ="云计算任务-"+a.replace(/\//g,'-')+"-"+Math.floor(Math.random()*(10000+1));

        },
        methods: {
            // OSS上传
            async putObject (OSSdata,data) {
                try {
                    // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。

                    let storePath=OSSdata.storePath.split('{fileName}');
                    console.log(data);
                    let name=`${storePath[0]}${data.name}`;
                    // console.log(name);
                    let result = await this.client.put(name, data);
                    console.log('result',result);
                    this.returnMsgList.push({
                        result:result,
                        file:data
                    });
                    this.readFile(data);
                } catch (e) {
                    console.log(e);
                }
            },
            //点击上传
            clickUploadButton(status){
                console.log(status);
                this.UpLoadStatus=status;
                this.$refs.ImgFile.click();
            },
            //拿到文件
            fileImport(e){
                let _this = this;
                console.log(this.UpLoadStatus,e.target.files);
                if(this.UpLoadStatus=='first'){
                    console.log('第一次');
                    this.UploadShou=false;
                    _this.getImgList=[];
                    let file = e.target.files;
                    for(let i in [...Array(file.length).keys()]){
                        _this.getImgList.push({
                            url:window.URL.createObjectURL(file[i]),
                            file:file[i]
                        });
                    }

                }else if(this.UpLoadStatus=='add'){
                    console.log('添加');
                    let file = e.target.files;
                    for(let i in [...Array(file.length).keys()]){
                        let reader = new FileReader();
                        reader.readAsDataURL(file[i]);
                        reader.onloadend = function () {
                            _this.getImgList.push({
                                url:reader.result,
                                file:file[i]
                            });
                        };
                    }
                }

                // this.uploadingFileList=[];
                // var image_list=[];
                //
                // for(var i=0,len=e.target.files.length; i<len; i++){
                //     this.uploadingFileList.push(e.target.files[i]);
                //     image_list.push({
                //         'id':e.target.files[i].name.split('.')[0],
                //         'path':`./${e.target.files[i].name}`
                //     });
                // }
                //
                // console.log(image_list);
                //
                // var blob = new Blob([JSON.stringify(image_list)], {type: "text/plain;charset=utf-8"});
                // FileSaver.saveAs(blob, `image_list.json`);

            },
            deleteImgFn(item,ind){
                this.getImgList.splice(ind, 1);
            },
            //创建任务并注册后期要用的所有资源作业
            CreateAndUpload(){
                let _this=this;

                this.UploadProgress=1;

                let data={
                    "bf_id": this.getTaskData.id,//
                    "task_name": this.taskName,
                    // "task_image": "string"
                };
                this.$Java_post("/terra_task/add", data).then(res=> {
                    console.log("创建terra任务",res);
                    if (res.code == 1) {
                        this.TerrataskData=res.data;
                        sessionStorage.setItem("TerraTaskID",res.data[0].terra_task_id);
                        res.data.forEach((item,ind)=>{

                            let names=item.data_name.split('_');
                            console.log(names[names.length - 1]);
                            switch (names[names.length-1]) {
                                case 'PhotoRes':
                                    this.CreateRes(item.data_name,'PhotoRes',item.id);
                                    break;
                                case 'Res2D':
                                    this.CreateRes(item.data_name,'Res2D',item.id);
                                    break;
                                case 'Res3D':
                                    this.CreateRes(item.data_name,'Res3D',item.id);
                                    break;
                                case 'Zip2D':
                                    this.CreateRes(item.data_name,'Zip2D',item.id);
                                    break;
                                case 'Zip3D':
                                    this.CreateRes(item.data_name,'Zip3D',item.id);
                                    break;
                                case 'Job2D':
                                    this.CreateJob(item.data_name,'Job2D',item.id);
                                    break;
                                case 'Job3D':
                                    this.CreateJob(item.data_name,'Job3D',item.id);
                                    break;
                                case 'ZipJob2D':
                                    this.CreateJob(item.data_name,'ZipJob2D',item.id);
                                    break;
                                case 'ZipJob3D':
                                    this.CreateJob(item.data_name,'ZipJob3D',item.id);
                                    break;

                            }

                        });
                        // _this.$T_post("/terra_task/add", data).then(res=> {
                        //     console.log("创建terra任务",res);
                        //     if (res.code == 1) {
                        //
                        //         this.$Message.info("数据更新完成");
                        //         // this.CreateRes(name,type,To)
                        //     } else {
                        //         this.$Message.error(res.msg_customer);
                        //     }
                        // });

                    } else {
                        this.$Message.error(res.msg_customer);
                    }
                });
            },
            // 创建资源
            CreateRes(name,type,id){
                var _this=this;

                _this.$T_post('/terra-hz/terra-rescon-be/v2/resources',{
                    name:name,
                    meta:'pm_id'+this.userInfo.pm_id,
                    type:'map',
                }).then(res => {
                    console.log(res.data);
                    if (res.result.code === 0) {
                        switch (type) {
                            case 'PhotoRes':
                                _this.CreateTaskResult.PhotoRes.uuid=res.data.uuid;
                                _this.CreateTaskResult.PhotoRes.name=name;
                                _this.CreateTaskResult.PhotoRes.id=id;
                                _this.CreateImgMagFile();//生成image_list.json
                                break;
                            case 'Res2D':
                                _this.CreateTaskResult.Res2D.uuid=res.data.uuid;
                                _this.CreateTaskResult.Res2D.name=name;
                                 _this.CreateTaskResult.Res2D.id=id;
                                break;
                            case 'Res3D':
                                _this.CreateTaskResult.Res3D.uuid=res.data.uuid;
                                _this.CreateTaskResult.Res3D.name=name;
                                 _this.CreateTaskResult.Res3D.id=id;
                                break;
                            case 'Zip2D':
                                _this.CreateTaskResult.Zip2D.uuid=res.data.uuid;
                                _this.CreateTaskResult.Zip2D.name=name;
                                 _this.CreateTaskResult.Zip2D.id=id;
                                break;
                            case 'Zip3D':
                                _this.CreateTaskResult.Zip3D.uuid=res.data.uuid;
                                _this.CreateTaskResult.Zip3D.name=name;
                                 _this.CreateTaskResult.Zip3D.id=id;
                                break;
                        }
                    }
                }).catch(err => {
                    console.log(err);
                    _this.$notice.error({
                        title: "新建资源失败!",
                        desc: err.toString()
                    });

                })
            },
            // 创建作业
            CreateJob(name,type,id){
                var _this=this;

                _this.$T_post('/terra-hz/terra-rescon-be/v2/jobs',{
                    name:name,
                    meta:'pm_id'+this.userInfo.pm_id
                }).then(res => {
                    console.log(res.data);
                    if (res.result.code === 0) {
                        switch (type) {
                            case 'Job2D':
                                _this.CreateTaskResult.Job2D.uuid=res.data.uuid;
                                _this.CreateTaskResult.Job2D.name=name;
                                 _this.CreateTaskResult.Job2D.id=id;
                                break;
                            case 'Job3D':
                                _this.CreateTaskResult.Job3D.uuid=res.data.uuid;
                                _this.CreateTaskResult.Job3D.name=name;
                                 _this.CreateTaskResult.Job3D.id=id;
                                break;
                            case 'ZipJob2D':
                                _this.CreateTaskResult.ZipJob2D.uuid=res.data.uuid;
                                _this.CreateTaskResult.ZipJob2D.name=name;
                                 _this.CreateTaskResult.ZipJob2D.id=id;
                                break;
                            case 'ZipJob3D':
                                _this.CreateTaskResult.ZipJob3D.uuid=res.data.uuid;
                                _this.CreateTaskResult.ZipJob3D.name=name;
                                 _this.CreateTaskResult.ZipJob3D.id=id;
                                break;
                        }
                    }
                }).catch(err => {
                    console.log(err);
                    _this.$notice.error({
                        title: "新建作业失败!",
                        desc: err.toString()
                    });
                })
            },
            //上传资源作业名称和uuid储存到后台
            upLoadNames(){
                this.$Java_post("/terra_task/modify_child", this.CreateTaskResult).then(res=> {
                    console.log("上传资源作业名称储存到后台",res);
                    if (res.code == 1) {

                    } else {
                        this.$Message.error(res.msg_customer);
                    }
                });
            },
            //创建image_list.json
            CreateImgMagFile(){
                var _this=this;
                this.uploadingFileList=[];
                var image_list=[];

                for(var i=0,len=this.getImgList.length; i<len; i++){
                    this.uploadingFileList.push(this.getImgList[i].file);
                    image_list.push({
                        'id':this.getImgList[i].file.name.split('.')[0],
                        'path':`./${this.getImgList[i].file.name}`
                    });
                }

                console.log(image_list);

                var blob = new Blob([JSON.stringify(image_list)], {type: "text/plain;charset=utf-8"});
                FileSaver.saveAs(blob, `image_list.json`);
                setTimeout(()=>{
                    _this.UploadProgress=2
                },2000)

            },
            getImgMag(){
                this.upLoadNames();//上传资源作业名称储存到后台

                this.$refs.ImgMsgFile.click();
                this.$_bus.$emit("getTerraToken");
            },
            //拿照片信息文件提交数据到oss
            uploadingOSS(e){
                var _this=this;
                this.uploadingFileList.push(e.target.files[0]);

                console.log("uploadingFileList",this.uploadingFileList);
                _this.UploadProgress=3;
                var i=0,len=this.uploadingFileList.length;
                var upCallBack=()=>{
                    console.log(`上传了${i}`);
                    this.uploadingPercentVal=parseInt(i/len*100);
                    if(i<len){
                        this.putObject(this.Terra_token,this.uploadingFileList[i]).then(()=>{
                            i++;
                            upCallBack()
                        });
                    }else {
                        console.log('成功上传');
                        setTimeout(()=>{
                            _this.uploadCallback();
                        },300);

                    }
                };

                upCallBack();



            },
            //上传回调
            uploadCallback(){
                var _this=this;
                //生成上传需要的文件信息
                this.upload_callback_data={
                    callbackParam:this.Terra_token.callbackParam,
                    files:[],
                    resourceUUID:this.CreateTaskResult.PhotoRes.uuid
                };
                console.log("returnMsgList",this.returnMsgList);
                console.log("str",this.str);
                this.returnMsgList.forEach((item,ind)=>{
                    if(ind==this.returnMsgList.length-3){
                        _this.$_bus.$emit("getTerraToken");
                    }
                    this.str.forEach((item2,ind2)=>{
                        if(item.file.name==item2.name){
                            console.log(item.file.name, item2.name);
                            this.upload_callback_data.files.push({
                                checksum:item2.sha1,
                                etag:JSON.parse(item.result.res.headers.etag),
                                name:item.result.name.split("/")[item.result.name.split("/").length-1]
                            })
                        }
                    })
                });
                this.upload_callback_data.callbackParam=this.Terra_token.callbackParam;
                console.log("upload_callback_data",this.upload_callback_data);
                this.$T_post('/terra-hz/terra-rescon-be/v2/store/upload_callback',this.upload_callback_data).then(res => {
                    console.log(res);
                    _this.$Notice.success({
                        title: "云计算任务创建成功!"
                    });
                    _this.$router.push({
                        path: "/resourceIndex/uploadingPage",
                        query: {
                            TaskID:_this.$route.query.TaskID,
                            status:'createJob'
                        }
                    });
                    _this.$router.go(0);
                }).catch(err => {
                    console.log(err);
                    this.$Modal.error({
                        title: '云计算任务创建失败',
                        content: '<p>图片格式错误或重叠率过低!</p>',
                        onOk:()=>{
                            this.$router.push({
                                path: "/resourceIndex/Terra"
                            });
                        }
                    });

                })
            },
            // 文件sha1加密
            readFile(event) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(event);
                reader.onload =(ev)=> {
                    try {
                        let file = ev.target.result;
                        let str = sha1(file);
                        this.str.push({
                            sha1:str,
                            name:event.name
                        });
                    } catch (e) {
                        console.log(e);
                    }
                };
            },

        },
    };
</script>

<style scoped lang="scss">
    #imgUpload{
        position: relative;
        padding-bottom: 50px;
        width: 100%;
        height: 100%;
        overflow: auto;
        .getBack{
            background-color: #fff;
            width: 100%;
            height: 50px;
            padding-left: 20px;
            cursor:pointer;
            margin-bottom: 25px;
            border-top: 1px solid #f3f3f3;
            i{
                font-size: 25px;
                color: #333333;
                margin-right: 6px;
                line-height: 50px;
            }
            span{
                font-size: 20px;
                color: #333333;
                line-height: 50px;
            }
        }
        .wrap{
            padding: 0 20px;
            .TaskName{
                margin-bottom: 60px;
                >h1{
                    font-size: 17px;
                    color: #999999;
                }
                .searchProject{
                    width: 300px;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-top: 6px;
                    input{
                        width: 100%;
                        height: 40px;
                        font-size: 16px;
                        color: #2d2d2d;
                        border: none;
                        background-color: #FAFAFA;
                        border-bottom: 1px solid #DCDEE2;
                        padding: 0 30px;
                        text-align: center;
                    }
                    input:focus::-webkit-input-placeholder{
                        color: transparent;
                    }
                    i{
                        position: absolute;
                        top: 10px;
                        left: 0;
                        font-size: 24px;
                        color: #DCDEE2;
                    }

                }
            }
            .UploadContent{
                width: 300px;
                height: 240px;
                /*background-color: #f3f3f3;*/
                /*border: 1px dashed #dadada;*/
                padding: 16px;
                border: 1px dashed transparent;
                background: linear-gradient(#f3f3f3,#f3f3f3) padding-box,
                repeating-linear-gradient(-45deg,#dadada 0, #dadada 12px,#f3f3f3 0,#f3f3f3 23px);
                cursor:pointer;
                position: relative;
                >i{
                    margin-top: 25px;
                    font-size: 100px;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .ImgFile{

                }
                .Button{
                    margin-top: 140px;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            .imgListContent{
                .imgList{
                    width: 300px;
                    height: auto;
                    /*background-color: #f3f3f3;*/
                    /*border: 1px dashed #dadada;*/
                    padding: 10px;
                    border: 1px dashed transparent;
                    background: linear-gradient(#f3f3f3,#f3f3f3) padding-box,
                    repeating-linear-gradient(-45deg,#dadada 0, #dadada 12px,#f3f3f3 0,#f3f3f3 23px);
                    float: left;
                    margin-top: 20px;
                    .imgItem{
                        width: 60px;
                        height: 60px;
                        position: relative;
                        background-color: #818181;
                        margin: 4px;
                        display: inline-block;
                        img{
                            width: 100%;
                            height: auto;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        .deleteImg{
                            width: 20px;
                            height: 20px;
                            border-radius: 20px;
                            background-color: #fff;
                            position: absolute;
                            top: -6px;
                            right: -6px;
                            cursor:pointer;
                            >i{
                                color: #2196F3;
                                font-size: 20px;
                            }
                        }

                    }

                }
            }

        }
        .Spin{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            .procedureBox{
                width: 250px;
                position: absolute;
                z-index: 100;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .text{
                    position: relative;
                    margin-bottom: 20px;
                    >div{
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                    }
                    i{
                        color: #2d8cf0;
                        font-size: 18px;
                        line-height: 30px;
                    }
                    h1{
                        font-size: 18px;
                        color: #404040;
                        line-height: 30px;
                        margin-right: 10px;
                    }
                    img{
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        width: 16px;
                        height: 16px;
                    }
                }
            }
            .bgc{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: #fff;
                opacity: .9;
            }
        }

    }
    #imgUpload::-webkit-scrollbar{
        display: none;
    }
</style>
