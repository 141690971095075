import { render, staticRenderFns } from "./UpZip.vue?vue&type=template&id=bb2f64ba&scoped=true&"
import script from "./UpZip.vue?vue&type=script&lang=js&"
export * from "./UpZip.vue?vue&type=script&lang=js&"
import style0 from "./UpZip.vue?vue&type=style&index=0&id=bb2f64ba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb2f64ba",
  null
  
)

export default component.exports