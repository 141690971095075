<template>
  <div id="UpZip">
    <!--返回之前页面-->
    <div class="getBack"
         @click="$router.back()">
      <i class="ivu-icon ivu-icon-ios-arrow-back"></i>
      <span>返回</span>
    </div>
    <div class="select">
      <h1>上传类型</h1>
      <div class="selectContent">
        <div class="task"
             :style="TaskType==0?LoadingStyle_pr:LoadingStyle"
             @click="TaskType=0">
          <img :src="TaskType==0?icon.build_map_pr:icon.build_map"
               alt="">
          <span :style="TaskType==0?'color:#fff':'color:#2196F3'">正射地图</span>
        </div>
        <div class="upLoading"
             :style="TaskType==1?LoadingStyle_pr:LoadingStyle"
             @click="TaskType=1">
          <img :src="TaskType==1?icon.build_model_pr:icon.build_model"
               alt="">
          <span :style="TaskType==1?'color:#fff':'color:#2196F3'">三维模型</span>
        </div>
      </div>
    </div>
    <div class="wrap"
         v-if="TaskType=='0'">
      <div class="TaskName">
        <h1>正射名称</h1>
        <div class="searchProject">
          <input type="text"
                 v-model="orthographicUpLoadData.mapname"
                 placeholder="填写您的正射名称">
        </div>
      </div>
      <Upload ref="OrUpLoad"
              name="zip"
              :disabled="orthographicUpLoadData.mapname==''"
              :show-upload-list="true"
              :format="['zip','ZIP']"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              :on-success="OrthophotoSuccess"
              :on-error="OrthophotoError"
              multiple
              type="drag"
              :data="orthographicUpLoadData"
              :action="UploadURL"
              style="display: inline-block;width: 100%;margin-bottom: 40px">
        <div style="padding: 40px 0; width: 100%">
          <Icon type="ios-cloud-upload"
                size="52"
                style="color: #3399ff"></Icon>
          <p style="font-size: 16px">点击或将文件拖动到这里进行上传</p>
          <p>(必须填写名称才可进行上传)</p>
        </div>
      </Upload>
      <div class="attention">
        <div class="Divider"></div>
        <p>上传仅支持ZIP格式，请创建一个母文件夹将正射地图打包并压缩；</p>
        <p>文件仅支持数字或英文命名；</p>
        <p>仅支持符合火星坐标系的正射影像文件。</p>
      </div>
    </div>

    <div class="wrap"
         v-if="TaskType=='1'">
      <div class="TaskName">
        <h1>模型名称</h1>
        <div class="searchProject">
          <input type="text"
                 v-model="UpLoad3DData.modelname"
                 placeholder="填写您的模型名称">
        </div>
      </div>
      <Upload ref="OrUpLoad"
              name="zip"
              :disabled="UpLoad3DData.modelname==''"
              :show-upload-list="true"
              :format="['zip','ZIP']"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              :on-success="OrthophotoSuccess"
              :on-error="OrthophotoError"
              multiple
              type="drag"
              :data="orthographicUpLoadData"
              :action="UploadURL"
              style="display: inline-block;width: 100%;margin-bottom: 40px">
        <div style="padding: 40px 0; width: 100%">
          <Icon type="ios-cloud-upload"
                size="52"
                style="color: #3399ff"></Icon>
          <p style="font-size: 16px">点击或将文件拖动到这里进行上传</p>
          <p>(必须填写名称才可进行上传)</p>
        </div>
      </Upload>
      <div class="attention">
        <div class="Divider"></div>
        <p>上传仅支持ZIP格式，请创建一个母文件夹将模型打包并压缩；</p>
        <p>文件仅支持数字或英文命名；</p>
        <p>仅支持3DTiles的模型文件。</p>
      </div>
    </div>

  </div>
</template>

<script>
import build_map from '@/assets/img/statistics/build_map.png';
import build_map_pr from '@/assets/img/statistics/build_map_pr.png';
import build_model from '@/assets/img/statistics/build_model.png';
import build_model_pr from '@/assets/img/statistics/build_model_pr.png';

import Api from '@/utils/api.js';

export default {
  data() {
    return {
      icon: {
        build_map,
        build_map_pr,
        build_model,
        build_model_pr,
      },
      LoadingStyle: {
        backgroundColor: '#fff',
        border: '1px solid #DEDEDE',
      },
      LoadingStyle_pr: {
        backgroundColor: '#4C98FF',
        border: '1px solid #4C98FF',
      },
      TaskType: '0', //0,正射  1,模型
      UploadShou: true,
      //上传地址
      UploadURL: '',
      // 上传的数据
      //正射
      orthographicUpLoadData: {
        mapname: '',
        mapdate: '',
        mapcount: 0, //照片数量
        maparea: 0, //地图面积
        pm_first_id: '',
        pm_second_id: 0,
        bf_id: '', //任务id
      },
      MapDate: '',
      //模型
      UpLoad3DData: {
        modelname: '',
        modelarea: 0, //地图面积
        pm_first_id: '',
        pm_second_id: 0,
        bf_id: '', //任务id
      },
    };
  },
  computed: {
    getTaskData() {
      return JSON.parse(window.sessionStorage.getItem('getTaskData') || '{}');
    },
    userInfo() {
      return JSON.parse(window.sessionStorage.getItem('user_info') || '{}');
    },
  },
  watch: {
    TaskType(val) {
      if (val == '0') {
        //正射
        this.UploadURL =
          Api.getOrthophotoUpDataUrl() + sessionStorage.getItem('token');
      } else if (val == '1') {
        //模型
        this.UploadURL = Api.get3DUpDataUrl() + sessionStorage.getItem('token');
      }
    },
  },
  mounted() {
    var a = new Date().toLocaleDateString();
    this.orthographicUpLoadData.mapname =
      '正射-' +
      a.replace(/\//g, '-') +
      '-' +
      Math.floor(Math.random() * (10000 + 1));
    this.UpLoad3DData.modelname =
      '模型-' +
      a.replace(/\//g, '-') +
      '-' +
      Math.floor(Math.random() * (10000 + 1));

    this.orthographicUpLoadData.pm_first_id =
      this.userType == 0 ? this.Project1info.pm_id : this.userInfo.pm_id;
    this.orthographicUpLoadData.bf_id = this.getTaskData.id;
    this.orthographicUpLoadData.mapdate = a.replace(/\//g, '-');
    this.UpLoad3DData.pm_first_id =
      this.userType == 0 ? this.Project1info.pm_id : this.userInfo.pm_id;
    this.UpLoad3DData.bf_id = this.getTaskData.id;

    //进入页面默认正射
    this.UploadURL =
      Api.getOrthophotoUpDataUrl() + sessionStorage.getItem('token');
  },
  methods: {
    // 上传
    handleFormatError(file) {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '文件格式请选择zip压缩包.',
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: '超过限定大小限制',
        desc: '上传的图片大小不得大于XXXX.',
      });
    },
    OrthophotoSuccess() {
      this.$Message.info('上传成功');
      this.$router.push({
        path: '/resourceIndex/Terra',
      });
    },
    OrthophotoError(error) {
      this.$Message.error(error);
    },
  },
};
</script>

<style scoped lang="scss">
#UpZip {
  position: relative;
  padding-bottom: 50px;
  width: 100%;
  height: 100%;
  overflow: auto;
  .getBack {
    background-color: #fff;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    cursor: pointer;
    margin-bottom: 25px;
    border-top: 1px solid #f3f3f3;
    i {
      font-size: 25px;
      color: #333333;
      margin-right: 6px;
      line-height: 50px;
    }
    span {
      font-size: 20px;
      color: #333333;
      line-height: 50px;
    }
  }
  .select {
    padding: 10px 20px 50px 20px;
    > h1 {
      font-size: 17px;
      color: #111;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .selectContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        width: 136px;
        height: 62px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 10px;
        cursor: pointer;
        transition: all 0.4s ease-in;
      }
      > div:hover {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
      }
    }
  }
  .wrap {
    padding: 0 20px;
    .TaskName {
      margin-bottom: 60px;
      > h1 {
        font-size: 17px;
        color: #111;
        font-weight: bold;
      }
      .searchProject {
        width: 300px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 6px;
        input {
          width: 100%;
          height: 40px;
          font-size: 16px;
          color: #2d2d2d;
          border: none;
          background-color: #fafafa;
          border-bottom: 1px solid #dcdee2;
          padding: 0 30px;
          text-align: center;
        }
        input:focus::-webkit-input-placeholder {
          color: transparent;
        }
        i {
          position: absolute;
          top: 10px;
          left: 0;
          font-size: 24px;
          color: #dcdee2;
        }
      }
    }
    .attention {
      .Divider {
        width: 100%;
        height: 1px;
        background-color: #e8eaec;
        margin-bottom: 20px;
      }
      > p {
        color: #999999;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
  }
}
</style>
