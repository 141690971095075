<template>
    <div id="uploadingPage">
        <div class="uploadingPageBox">
            <!--高德地图-->
            <div class="MapBox">
                <Map
                        :config="mapConfig"
                        ref="commonMap"
                />
            </div>
            <!--侧边栏-->
            <div class="leftBar" :style='style'>
                <div class="leftMain">
                    <imgUpload
                            v-if="leftBarStatus=='imgUpload'"
                            :CreateTaskResult="CreateTaskResult"
                            :client="client"
                    />
                    <createJob
                            v-if="leftBarStatus=='createJob'"
                            :CreateTaskResult="CreateTaskResult"
                    />
                    <UpZip
                            v-if="leftBarStatus=='UpZip'"
                    />
                </div>
                <div class="unfoldBth" @click="leftBarShou=!leftBarShou">
                    <i :class="leftBarShou?'ivu-icon ivu-icon-ios-arrow-back':'ivu-icon ivu-icon-ios-arrow-forward'" ></i>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import Map from "@/components/statistics/commonComponents/map.vue";

    import modelUpload from './modelUpload.vue'
    import imgUpload from './imgUpload.vue'
    import createJob from './createJob.vue'
    import UpZip from './UpZip.vue'

    export default {
        props: {
            client: {
                type: Object,
                required: false,
                default: () => {}
            }
        },
        components: {
            Map,
            modelUpload,
            imgUpload,
            createJob,
            UpZip
        },
        data() {
            return {
                mapConfig: {
                    tools: [ "search", "measure","hierarchy", "zoomIn", "zoomOut"],
                    updateBySocket: false
                },
                style:{
                    left: '0'
                },
                leftBarShou:true,
                leftBarStatus:'imgUpload',
                // leftBarStatus:'createJob',
                //资源作业的名称数据
                CreateTaskResult:{
                    PhotoRes:{
                        name:'',
                        id:'',
                        uuid:''
                    },
                    Res2D:{
                        name:'',
                        id:'',
                        uuid:''
                    },
                    Res3D:{
                        name:'',
                        id:'',
                        uuid:''
                    },
                    Zip2D:{
                        name:'',
                        id:'',
                        uuid:''
                    },
                    Zip3D:{
                        name:'',
                        id:'',
                        uuid:''
                    },
                    Job2D:{
                        name:'',
                        id:'',
                        uuid:''
                    },
                    Job3D:{
                        name:'',
                        id:'',
                        uuid:''
                    },
                    ZipJob2D:{
                        name:'',
                        id:'',
                        uuid:''
                    },
                    ZipJob3D:{
                        name:'',
                        id:'',
                        uuid:''
                    }
                },
            };
        },
        watch:{
            leftBarShou(val){
                if(val){
                    this.style.left='0'
                }else {
                    this.style.left='-340px'
                }
            }
        },
        mounted() {
            this.leftBarStatus=this.$route.query.status;
            this.ShouBuildFigureMap()
        },
        methods: {
            //显示建图任务区域
            ShouBuildFigureMap(){
                this.$refs.commonMap.ShouBuildFigure();
            }
        },
    };
</script>

<style scoped lang="scss">
    #uploadingPage{
        width: 100%;
        height: 100%;
        .uploadingPageBox{
            width: 100vw;
            height: 100%;
            background-color: #fff;
            position: fixed;
            left: 0px;
            .MapBox{
                width: 100%;
                height: 100%;
            }
            .leftBar{
                width: 340px;
                height: 100%;
                /*border-top: 1px solid #f3f3f3;*/
                position: absolute;
                left: 0;
                top:0;
                transition: all 0.5s ease-in;
                .leftMain{
                    width:100%;
                    height: 100%;
                    background-color: #fafafa;
                }
                .unfoldBth{
                    position: absolute;
                    right: -26px;
                    top: 10px;
                    width: 26px;
                    height: 48px;
                    background-color: #fff;
                    box-shadow: 4px 2px 6px rgba(0, 0, 0, 0.1);
                    border-radius: 0 6px 6px 0;
                    i{
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        font-size: 26px;
                        line-height: 48px;
                    }
                }
            }
        }
    }
</style>
