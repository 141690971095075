<template>
    <div id="createJob">
        <!--返回之前页面-->
        <div
                class="getBack"
                @click="Back()"
        >
            <i class="ivu-icon ivu-icon-ios-arrow-back"></i>
            <span>返回</span>
        </div>
        <div class="select">
            <h1>生成类型</h1>
            <div class="selectContent">
                <div class="task"
                     :style="TaskType==0?LoadingStyle_pr:LoadingStyle"
                     @click="TaskType=0"
                     v-if="is_all_end==0?true:is_all_end==1?false:true"
                >
                    <img :src="TaskType==0?icon.build_map_pr:icon.build_map" alt="">
                    <span  :style="TaskType==0?'color:#fff':'color:#2196F3'">正射地图</span>
                </div>
                <div class="upLoading"
                     :style="TaskType==1?LoadingStyle_pr:LoadingStyle"
                     @click="TaskType=1"
                     v-if="is_all_end==0?true:is_all_end==1?true:false"
                >
                    <img :src="TaskType==1?icon.build_model_pr:icon.build_model" alt="">
                    <span :style="TaskType==1?'color:#fff':'color:#2196F3'">三维模型</span>
                </div>
            </div>
        </div>
        <div class="wrap" v-if="TaskType=='0'">
            <div class="TaskName">
                <h1>正射名称</h1>
                <div class="searchProject">
                    <input type="text" v-model="orthophotoName" placeholder="填写您的正射名称" >
                </div>
            </div>
            <div class="configBox">
                <h1>地图场景</h1>
                <div class="scene">
                    <div class="city"
                         :style="sceneType==1?LoadingStyle_pr:LoadingStyle"
                         @click="sceneType=1"
                    >
                        <img :src="sceneType==1?icon.build_map_city_pr:icon.build_map_city" alt="">
                        <span  :style="sceneType==1?'color:#fff':'color:#2196F3'">城市模式</span>
                    </div>
                    <div class="farm"
                         :style="sceneType==0?LoadingStyle_pr:LoadingStyle"
                         @click="sceneType=0"
                    >
                        <img :src="sceneType==0?icon.build_map_farm_pr:icon.build_map_farm" alt="">
                        <span :style="sceneType==0?'color:#fff':'color:#2196F3'">农田模式</span>
                    </div>
                    <div class="fruittree"
                         :style="sceneType==2?LoadingStyle_pr:LoadingStyle"
                         @click="sceneType=2"
                    >
                        <img :src="sceneType==2?icon.build_map_fruittree_pr:icon.build_map_fruittree" alt="">
                        <span :style="sceneType==2?'color:#fff':'color:#2196F3'">果树模式</span>
                    </div>
                </div>
                <h1>清晰度</h1>
                <div class="definitionBox">
                    <Select v-model="orthophotoDefinition" >
                        <Option v-for="item in definitionList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                </div>
            </div>
        </div>
        <div class="wrap" v-if="TaskType=='1'">
            <div class="TaskName">
                <h1>模型名称</h1>
                <div class="searchProject">
                    <input type="text" v-model="modelName" placeholder="填写您的模型名称" >
                </div>
            </div>
            <div class="configBox">
                <h1>清晰度</h1>
                <div class="definitionBox">
                    <Select v-model="modelDefinition" >
                        <Option v-for="item in definitionList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                </div>
            </div>
        </div>
        <div class="affirm">
            <Button size="large" type="primary" @click="startTask">开启云计算任务</Button>
        </div>
    </div>
</template>

<script>
    import build_map from '@/assets/img/statistics/build_map.png'
    import build_map_pr from '@/assets/img/statistics/build_map_pr.png'
    import build_model from '@/assets/img/statistics/build_model.png'
    import build_model_pr from '@/assets/img/statistics/build_model_pr.png'
    import build_map_city from '@/assets/img/statistics/build_map_city.png'
    import build_map_city_pr from '@/assets/img/statistics/build_map_city_pr.png'
    import build_map_farm from '@/assets/img/statistics/build_map_farm.png'
    import build_map_farm_pr from '@/assets/img/statistics/build_map_farm_pr.png'
    import build_map_fruittree from '@/assets/img/statistics/build_map_fruittree.png'
    import build_map_fruittree_pr from '@/assets/img/statistics/build_map_fruittree_pr.png'

    export default {
        props: {
            CreateTaskResult: {
                type: Object,
                required: false,
                default: () => {}
            },
        },
        data() {
            return {
                icon:{
                    build_map,
                    build_map_pr,
                    build_model,
                    build_model_pr,
                    build_map_city,
                    build_map_city_pr,
                    build_map_farm,
                    build_map_farm_pr,
                    build_map_fruittree,
                    build_map_fruittree_pr
                },
                LoadingStyle:{
                    backgroundColor:'#fff',
                    border:'1px solid #DEDEDE'
                },
                LoadingStyle_pr:{
                    backgroundColor:'#4C98FF',
                    border:'1px solid #4C98FF'
                },
                TaskType:'0',//0,正射  1,模型
                orthophotoName:'',
                modelName:'',
                sceneType:'1',// 0,农田 1,城市 2,果树
                definitionList:[
                    {
                        value: '1',
                        label: '高'
                    },
                    {
                        value: '2',
                        label: '中'
                    },
                    {
                        value: '3',
                        label: '低'
                    }
                ],
                orthophotoDefinition:'1',
                modelDefinition:'2',
                TerraTaskData:null, //当前云计算任务下所有资源作业名称和uuid
                parameters2D:{
                    "map_mode":1,         //可选，拼图模式 0代表农田模式 1代表城市模式 2代表果树模式 4代表多光谱模式 5代表重建处方图。默认为1
                    "quality_level": 1,   //可选，重建等级 1:高分辨率 2:中分辨率 3:低分辨率。默认为1
                    "use_gcj02":true,     //可选，是否使用国测局坐标系。默认true

                    // "aux_band_list":["Blue","Green","Red","RedEdge","NIR"],       //可选，多光谱模式中算法需要处理的波段名称列表
                    // "index_list":["NDVI","GNDVI","NDRE","LCI","OSAVI","MCARI"],    //可选，多光谱模式中算法需要生成的指数图列表

                    // 以下为两种输出地理坐标系的选择，二选一：

                    // 模式1,WKT字符串，往往从PRJ文件来
                    // "output_CS_from_WKT": "",          //可选，用户定义的输出坐标系。默认为空字符串

                    // 模式2，给坐标系字符串和可选的高程重载
                    // 地理坐标系：
                    "output_geo_CS": "EPSG:4326",               //可选，地理坐标系，用户选择，格式样例:"EPSG:4326"。默认为空字符串
                    // 高程坐标系重载，如果不需要修改高程则可忽略这个
                    // "output_override_vertical_CS": "",  //可选，重载高程坐标系，用户选择，格式样例:"EPSG:4326"。默认为空字符串
                    // 只有map_mode为5这些参数才生效
                    // "fertilizer_min": 1.0,
                    // "ndvi_value_for_fertilizer_min": 0.1,
                    // "fertilizer_mid": 1.0,
                    // "ndvi_value_for_fertilizer_mid": 0.1,
                    // "fertilizer_max": 1.0,
                    // "ndvi_value_for_fertilizer_max": 0.1,
                    // "grid_gsd": 5.0, // 最终输出的处方图的GSD（默认即可5.0，单位：m）
                    // "valid_label": 5 // 有效的语义类别（默认即可，5代表农田）
                },
                parameters3D:{
                    //"重建参数": 推荐值                 说明
                    "stereo_mode": true,              //可选，是否为立体模式，电力或环绕模式下建议开启。默认false
                    "cut_AOI": false,                  //可选，是否删去拍摄位置之外的部分。默认false

                    "output_pointcloud": false,        //可选，是否输出彩色点云，为true时以下两个generation才可能生效。默认false，若置为true会略微增加运算时间
                    "generate_pnts": false,             //可选，是否生成pnts格式的点云LOD模型。默认true
                    "generate_las": false,             //可选，是否生成LAS格式点云模型。默认false
                    "generate_point_s3mb": true,      //可选，是否生成s3mb格式点云模型。默认false

                    "output_mesh": true,               //可选，是否生成网格，为true时以下四个generation才可能生效。默认true
                    "generate_ply": false,              //可选，是否生成ply格式的纹理模型。默认true
                    "generate_obj": false,             //可选，是否生成obj格式的纹理模型。默认false
                    "generate_b3dm": true,             //可选，是否生成b3dm格式的网格LOD模型。默认true
                    "generate_osgb": false,            //可选，是否生成osgb格式的网格LOD模型。默认false
                    "generate_s3mb": false,            //可选，是否生成s3mb格式的网格LOD模型。默认false

                    "quality_level": 2,                //可选，重建的精细程度 1:高精度 2:中精度 3:低精度。默认2

                    // 以下为两种输出地理坐标系的选择，二选一：

                    // 模式1,WKT字符串，往往从PRJ文件来
                    // "output_CS_from_WKT": "",          //可选，用户定义的输出坐标系。默认为空字符串

                    // 模式2，给坐标系字符串和可选的高程重载
                    // 地理坐标系：
                    "output_geo_CS": "EPSG:4326",               //可选，地理坐标系，用户选择，格式样例:"EPSG:4326"。默认为空字符串
                    // 高程坐标系重载，如果不需要修改高程则可忽略这个
                    // "output_override_vertical_CS": ""  //可选，重载高程坐标系，用户选择，格式样例:"EPSG:4326"。默认为空字符串
                },
                is_all_end:0,//0都可以  1可以模型计算 2可以正射计算

            };
        },
        mounted() {
            var a=(new Date()).toLocaleDateString();
            this.orthophotoName ="正射-"+a.replace(/\//g,'-')+"-"+Math.floor(Math.random()*(10000+1));
            this.modelName ="模型-"+a.replace(/\//g,'-')+"-"+Math.floor(Math.random()*(10000+1));
            this.is_all_end=this.$route.query.is_all_end?this.$route.query.is_all_end:0;
            if(this.is_all_end==1){
                // TaskType:'0',//0,正射  1,模型
                this.TaskType=1
            }else if(this.is_all_end==2){
                this.TaskType=0
            }
            this.getTerraTask(sessionStorage.getItem('TerraTaskID'));

        },
        methods: {
            Back(){
                this.$router.push({
                    path: '/resourceIndex/Terra'
                });
            },
            //获取当前云计算任务的数据
            getTerraTask(id){
                var _this=this;
                this.$Java_post("/terra_task/getChildTask", {
                    terra_task_id:id
                }).then(res=> {
                    console.log("获取当前云计算任务的数据",res);
                    if (res.code == 1) {
                        _this.TerraTaskData=res.data;
                    } else {
                        this.$Message.error(res.msg_customer);
                    }
                });
            },
            // 开启云计算任务
            startTask(){
                var _this=this;
                if(this.TaskType==0){//0,正射
                    this.parameters2D.quality_level=Number(this.orthophotoDefinition);
                    this.parameters2D.map_mode=Number(this.sceneType);
                    let data={
                        resourceUuid:this.TerraTaskData.PhotoRes.uuid,
                        parameters:JSON.stringify(this.parameters2D),
                        type:0//0：2D重建，1：3D重建，3：zip压缩，4：合并任务
                    };
                    _this.$T_post(`/terra-hz/terra-rescon-be/v2/jobs/${this.TerraTaskData.Job2D.uuid}/start`,data).then(res => {
                        console.log(res.data);
                        if (res.result.code === 0) {
                            _this.$Java_post("/terra_task/modify_child", {
                                Res2D:{
                                    id:_this.TerraTaskData.Res2D.id,
                                    uuid:res.data.Job.OutputResource.UUID
                                },
                                Job2D:{
                                    id:_this.TerraTaskData.Job2D.id,
                                    job_state:'1'
                                }
                            }).then(res=> {
                                console.log("修改子任务数据",res);
                                if (res.code == 1) {
                                    _this.$router.push({
                                        path: "/resourceIndex/Terra"
                                    });
                                } else {
                                    this.$Message.error(res.msg_customer);
                                }
                            });
                        }
                    }).catch(err => {
                        console.log(err);
                        _this.$notice.error({
                            title: "开启作业失败!",
                            desc: err.toString()
                        });
                    });
                }else if(this.TaskType==1){//1,模型
                    this.parameters3D.quality_level=Number(this.modelDefinition);
                    let data={
                        resourceUuid:this.TerraTaskData.PhotoRes.uuid,
                        parameters:JSON.stringify(this.parameters3D),
                        type:1//0：2D重建，1：3D重建，3：zip压缩，4：合并任务
                    };
                    _this.$T_post(`/terra-hz/terra-rescon-be/v2/jobs/${this.TerraTaskData.Job3D.uuid}/start`,data).then(res => {
                        console.log(res.data);
                        if (res.result.code === 0) {
                            _this.$Java_post("/terra_task/modify_child", {
                                Res3D:{
                                    id:_this.TerraTaskData.Res3D.id,
                                    uuid:res.data.Job.OutputResource.UUID
                                },
                                Job3D:{
                                    id:_this.TerraTaskData.Job3D.id,
                                    job_state:'1'
                                }
                            }).then(res=> {
                                console.log("修改子任务数据",res);
                                if (res.code == 1) {

                                    _this.$router.push({
                                        path: "/resourceIndex/Terra"
                                    });
                                } else {
                                    this.$Message.error(res.msg_customer);
                                }
                            });


                        }
                    }).catch(err => {
                        console.log(err);
                        _this.$notice.error({
                            title: "开启作业失败!",
                            desc: err.toString()
                        });
                    });
                }

            }
        },
    };
</script>

<style scoped lang="scss">
    #createJob{
        position: relative;
        padding-bottom: 50px;
        width: 100%;
        height: 100%;
        overflow: auto;
        .getBack{
            background-color: #fff;
            width: 100%;
            height: 50px;
            padding-left: 20px;
            cursor:pointer;
            margin-bottom: 10px;
            border-top: 1px solid #f3f3f3;
            i{
                font-size: 25px;
                color: #333333;
                margin-right: 6px;
                line-height: 50px;
            }
            span{
                font-size: 20px;
                color: #333333;
                line-height: 50px;
            }
        }
        .select{
            padding: 30px 20px 50px 20px;

            >h1{
                font-size: 17px;
                color: #111;
                font-weight: bold;
                margin-bottom: 20px;
            }
            .selectContent{
                display: flex;
                justify-content: space-between;
                align-items: center;
                >div{
                    width: 136px;
                    height: 62px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding: 0 10px;
                    cursor:pointer;
                    transition: all 0.4s ease-in;
                }
                >div:hover{
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
                }
            }

        }
        .wrap{
            padding: 0 20px;
            .TaskName{
                margin-bottom: 60px;
                >h1{
                    font-size: 17px;
                    color: #111;
                    font-weight: bold;
                }
                .searchProject{
                    width: 300px;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-top: 6px;
                    input{
                        width: 100%;
                        height: 40px;
                        font-size: 16px;
                        color: #2d2d2d;
                        border: none;
                        background-color: #FAFAFA;
                        border-bottom: 1px solid #DCDEE2;
                        padding: 0 30px;
                        text-align: center;
                    }
                    input:focus::-webkit-input-placeholder{
                        color: transparent;
                    }
                    i{
                        position: absolute;
                        top: 10px;
                        left: 0;
                        font-size: 24px;
                        color: #DCDEE2;
                    }

                }
            }
            .configBox{
                margin-bottom: 100px;
                >h1{
                    font-size: 17px;
                    color: #111;
                    font-weight: bold;
                    margin-bottom: 20px;
                }
                .scene{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 70px;
                    >div{
                        width: 90px;
                        height: 36px;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        padding: 0 10px;
                        cursor:pointer;
                        transition: all 0.4s ease-in;
                    }
                    >div:hover{
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
                    }
                }

            }

        }
        .affirm{
            >button{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 100px;
            }
        }
    }
</style>
